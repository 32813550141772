<template>
  <div>
    <b-dropdown
      id="dropdown-right"
      right
      class="text-secondary dropdownTableActions"
      variant="link"
      toggle-class="text-decoration-none"
      no-caret
      @shown="tableOverflow('inherit')"
      @hide="tableOverflow('auto')">
      <template #button-content>
        <font-awesome-icon
          class="text-secondary"
          :icon="['fa', 'ellipsis']"
          size="2xl" />
      </template>
      <slot name="options"></slot>
      <template v-for="(option, index) in iconsBaseRender">
        <b-dropdown-item
          v-if="validationByOption(option.key)"
          @click="$emit('action', option.action, info)"
          :key="index + option.key + Math.floor(Math.random() * 999)">
          {{ validText(option) }}
          <font-awesome-icon
            :icon="validIcon(option)"
            :class="classByIconKey(option.key)"
            :size="option.iconSize" />
        </b-dropdown-item>
      </template>
    </b-dropdown>
  </div>
</template>

<script>
import { hasPermissionIcons } from "@/constants/permissions";
import { mapGetters } from "vuex";

export default {
  name: "PointsMenu",
  props: {
    info: {
      type: Object,
      required: false,
    },
    options: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      hasPermissionIcons,
      iconsBaseRender: [
        {
          key: "viewPatientHistory",
          text: "Historial",
          icon: "file-medical",
          iconSize: "lg",
          iconType: "fa",
          action: "viewPatientHistoryResume",
        },
        {
          key: "editPatientDetail",
          text: "Editar detalles",
          icon: "edit",
          iconSize: "lg",
          iconType: "fa",
          action: "goToDetails",
        },
        {
          key: "viewPatientDetail",
          text: "Vista previa detalle",
          icon: "eye",
          iconSize: "lg",
          iconType: "fa",
          action: "viewDetails",
        },
        {
          key: "uploadPaymentEvidence",
          text: "",
          icon: "",
          iconSize: "lg",
          iconType: "",
          action: "showPaymentEvidence",
        },
        {
          key: "generatePDF",
          text: "Generar PDF",
          icon: "file-pdf",
          iconSize: "lg",
          iconType: "fa",
          action: "generatePDF",
        },
        {
          key: "authCashRegister",
          text: "Autorizar",
          icon: "cash-register",
          iconSize: "lg",
          iconType: "fa",
          action: "authCashRegister",
        },
        {
          key: "viewAffiliationInfo",
          text: "Información",
          icon: "info-circle",
          iconSize: "lg",
          iconType: "fa",
          action: "openPreviewInfo",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["getUserType"]),
  },
  methods: {
    validIcon(option) {
      return option.key === "uploadPaymentEvidence"
        ? this.iconActions(this.info)
        : [option.iconType, option.icon];
    },
    validText(option) {
      return option.key === "uploadPaymentEvidence"
        ? this.titleHoverPaymentIcon(this.info)
        : option.text;
    },
    isSecondOpinion(appointment) {
      return (
        (appointment.is_paid === "free_of_charge" ||
          appointment.second_opinion_paid) &&
        appointment.second_opinion_doc.length > 0
      );
    },
    iconActions(appointment) {
      if (
        this.isSecondOpinion(appointment) ||
        appointment.second_opinion_paid
      ) {
        return ["fa", "user-group"];
      }
      return ["fa", "money-bill"];
    },
    isDisabledIcon(appointment) {
      if (
        !this.isPaidAppointment(appointment) &&
        !this.isSecondOpinion(appointment)
      ) {
        return true;
      }
      return false;
    },
    isAvailableIcon(appointment) {
      if (this.isPaidAppointment(appointment)) {
        return true;
      }
      if (this.isSecondOpinion(appointment)) {
        return true;
      }
      return false;
    },
    isPaidAppointment(appointment) {
      return (
        (appointment.is_paid === "paid_out" ||
          appointment.is_paid === "not_payed") &&
        appointment.vouchers.length > 0
      );
    },
    classByIconKey(type) {
      if (type === "uploadPaymentEvidence") {
        if (this.isAvailableIcon(this.info)) {
          return "ms-2 text-primary pointer";
        }
      }
      return "ms-2 text-secondary";
    },
    titleHoverPaymentIcon(row) {
      if (row.has_second_opinion || row.is_paid === "free_of_charge") {
        if (row.second_opinion_doc?.length > 0) {
          return "Archivo de segunda opinión";
        }
        return "Sin archivo de segunda opinión";
      }
      if (row.is_paid === "paid_out") return "Comprobante de pago";
      return "Sin comprobante de pago";
    },
    validationByOption(key) {
      const hasExtraValidation = ["uploadPaymentEvidence", "authCashRegister"];
      const hasPermission = this.hasIconsPermission(key);
      if (hasPermission && hasExtraValidation.includes(key)) {
        if (key === "uploadPaymentEvidence") {
          return this.info?.next_appointment_date;
        }
        if (key === "authCashRegister") {
          return (
            this.info?.next_appointment_id &&
            (this.info?.is_paid !== "not_payed" ||
              this.info?.is_paid !== "free_of_charge")
          );
        }
      }
      return hasPermission;
    },
    hasIconsPermission(icon) {
      if (Object.keys(hasPermissionIcons).includes(this.getUserType)) {
        return this.hasPermissionIcons[this.getUserType][icon];
      }
      return false;
    },
    tableOverflow(overflow) {
      if (window.innerWidth <= 768) {
        const tableContainer = document.querySelector(".table-responsive");
        if (tableContainer) {
          tableContainer.style["overflow-y"] = overflow;
        }
      } else {
        const tableContainer = document.querySelector(".table-responsive");
        if (tableContainer) {
          tableContainer.style.overflow = overflow;
        }
      }
    },
  },
};
</script>

<style lang="scss">
.dropdownTableActions.show {
  button {
    background-color: rgb(98, 144, 251, 0.12001709710984);
    border-radius: 100%;
    z-index: 1001;
  }
}
.dropdownTableActions {
  ul {
    box-shadow: 0 4px 12px 0 rgba(131, 129, 129, 0.11) !important;
    border: none !important;
    transform: translate3d(-220px, 14px, 0px);
    color: #6a6e83 !important;
    font-size: 12px !important;
    line-height: 14px !important;
    text-align: right !important;
    padding: 2rem !important;
    border-radius: 21px !important;
    li {
      margin-bottom: 0.6rem !important;
    }
  }
}
</style>
